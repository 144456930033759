bcl.contextHub = {
  props: {
    nameSessionStorage: "contextHub.",
    nameProfile: "profile",
    nameFav: "favorites",
    pending: "pending",
    url: "/bin/barcelo/loadProfile.json",
    ttl: 10 * 60 * 1000,
    stores: [],
    storesPending: [],
    lasukmybCookie: "lasukmyb",
    lasukmybErrorCookie: "lasukmyb-error",
    loginError: ["inactive-user", "not-userid", "not-useremail", "api-error", "generic-error"],
  },

  init: function () {
    const store = bcl.contextHub.get(bcl.contextHub.props.nameProfile);
    if (!store) {
      if (!bcl.contextHub.validLoginButton()) {
        bcl.contextHub.fixLoginButton();
      }

      bcl.ajax.postRequest({
        data: {},
        url: bcl.contextHub.props.url,
        callback: bcl.contextHub.getRequest,
        itemCallback: { store: bcl.contextHub.props.nameProfile },
      });
    } else {
      bcl.contextHub.executeAllPending(bcl.contextHub.props.nameProfile);
    }
  },

  validLoginButton: function () {
    const cookies = document.cookie.split(";");

    let cookieExists = false;
    let validCookie = false;
    const cookieName = "mybLoginCleanv3";
    const actualDate = new Date();

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(cookieName + "=") === 0) {
        cookieExists = true;
        const cookieValue = cookie.substring(cookieName.length + 1);
        const cookieExp = decodeURIComponent(cookieValue);
        if (isNaN(Date.parse(cookieExp))) {
          validCookie = false;
        } else {
          const expiration = new Date(cookieExp);
          if (expiration > actualDate) {
            validCookie = true;
          }
        }
        break;
      }
    }

    return cookieExists && validCookie;
  },
  fixLoginButton: function () {
    localStorage.clear();
    sessionStorage.clear();

    const getCookies = function () {
      return document.cookie.split(";").map((cookie) => cookie.trim());
    };

    const deleteCookie = function (name) {
      document.cookie = name + "=; Max-Age=0; path=/;";
    };

    const deleteCookiesFromBarcelo = function () {
      const cookies = getCookies();

      cookies.forEach((cookie) => {
        const [name] = cookie.split("=");
        deleteCookie(name);
      });
    };

    deleteCookiesFromBarcelo();

    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 6);

    document.cookie = "mybLoginCleanv3=true; expires=" + expirationDate.toUTCString() + "; path=/";
  },

  getRequest: function (res, obj) {
    if (!res.response) {
      return;
    }

    const store = {};
    if (res.getResponseHeader("X-Akamai-Edgescape")) {
      store.props = bcl.contextHub.parseResponse(res.getResponseHeader("X-Akamai-Edgescape") ? res.getResponseHeader("X-Akamai-Edgescape") : "");
    }
    store.ready = true;
    store.expiry = new Date().getTime() + bcl.contextHub.props.ttl;
    store.user = JSON.parse(res.response);

    sessionStorage.setItem(bcl.contextHub.props.nameSessionStorage + obj.store, JSON.stringify(store));

    bcl.contextHub.props.stores[obj.store] = store;

    bcl.contextHub.executeAllPending(obj.store);
  },

  parseResponse: function (akamai) {
    const pairs = akamai.split(",");
    const output = {};

    bcl.u.forEach(pairs, function (pair) {
      const keyValue = pair.split("=");
      if (keyValue.length == 2) {
        output[keyValue[0]] = keyValue[1];
      }
    });
    return output;
  },

  callWhenReady: function (func, name) {
    if (func && typeof func === "function") {
      const obj = bcl.contextHub.get(name);
      if (obj && obj.ready) {
        func();
      } else {
        bcl.contextHub.addPending(name, func);
      }
      return true;
    }
    return false;
  },

  isEnabled: function () {
    return true;
  },

  get: function (name) {
    let store = bcl.contextHub.props.stores[name];
    if (!store) {
      store = sessionStorage.getItem(bcl.contextHub.props.nameSessionStorage + name);
    }

    if (!store) {
      return;
    }

    if (typeof store === "string") {
      store = JSON.parse(store);
    }

    bcl.contextHub.props.stores[name] = store;

    const now = new Date();
    if (!store.expiry || store.expiry < now.getTime()) {
      store = null;
      delete bcl.contextHub.props.stores[name];
      sessionStorage.removeItem(bcl.contextHub.props.nameSessionStorage + name);
    }
    return store;
  },

  getItem: function (store, name) {
    let storeObj = store;
    if (typeof store === "string") {
      storeObj = bcl.contextHub.get(store);
      if (!storeObj) {
        return;
      }
    }

    if (typeof storeObj === "object" && storeObj.props) {
      return storeObj.props[name];
    }
  },

  getPending: function (storeName) {
    if (!bcl.contextHub.props.storesPending[storeName]) {
      bcl.contextHub.props.storesPending[storeName] = [];
    }
    return bcl.contextHub.props.storesPending[storeName];
  },

  addPending: function (store, func) {
    const pending = bcl.contextHub.getPending(store);
    pending.push(func);
  },

  executeAllPending: function (store) {
    const pending = bcl.contextHub.getPending(store);
    while (pending.length > 0) {
      const item = pending.shift();
      item();
    }
  },
};
