let loadedComponents = {};

const loadComponent = async function (name, component, options) {
  if (component?.isPresent(options)) {
    try {
      const importedComponent = await component?.importComponent({ name, root: options?.root });
      return !!importedComponent;
    } catch (error) {
      console.error(`Error importing ${name} component:`, error);
      console.log(error);
      return false;
    }
  }
  return false;
};

const BarceloComponents = {
  init: async (components, options = { root: document, ignoreForceImport: false, ignoreIsPresent: false }) => {
    for await (const [name, component] of Object.entries(components)) {
      const isLoaded = await loadComponent(name, component, options);
      loadedComponents = {
        ...loadedComponents,
        [name]: isLoaded,
      };
    }

    bcl.c.manifest = { ...bcl.c.manifest, ...loadedComponents };
  },
};

export default BarceloComponents;
