import axios from "axios";

bcl.ajax = {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  getRequest: function (item, url, callback, itemCallback, setHeader, errorCallback) {
    const config = {};
    if (setHeader) {
      config.headers = { "X-Requested-With": "XMLHttpRequest" };
    }
    axios
      .get(url, config)
      .then(function (response) {
        if (response.status >= 200 && response.status < 400) {
          if (item) {
            item.innerHTML = response.request.responseText;
          }

          if (callback) {
            if (typeof callback === "string") {
              bcl.u.executeFunctionByName(callback, window, response.request, item, itemCallback);
            } else if (typeof callback === "function") {
              callback(response.request, itemCallback);
            }
          }

          if (item) {
            document.dispatchEvent(new CustomEvent("ajax-executed", { detail: item }));
          }
        } else {
          bcl.u.removeBhgLoading();
          if (errorCallback) {
            errorCallback(itemCallback, response.request);
          }
        }
      })
      .catch(function (error) {
        console.error("Axios GET error in url:", url);
        console.error("GET error details:", error);
      });
  },

  postRequest: function (obj) {
    const data = obj.data || {},
      url = obj.url,
      isJson = obj.contentType === "json",
      errorCallback = obj.errorCallback;

    if (!url) {
      return;
    }

    // Axios post config
    const config = {
      headers: {
        "Content-Type": isJson ? "application/json" : "application/x-www-form-urlencoded",
      },
    };
    const formattedData = isJson ? JSON.stringify(data) : bcl.u.serializeObject(data);
    axios
      .post(url, formattedData, config)
      .then(function (response) {
        bcl.ajax.onLoad(obj, response.request);
      })
      .catch(function (error) {
        console.error("Axios POST error in url:", url);
        console.error("POST error details:", error);

        if (errorCallback) {
          errorCallback(obj.itemCallback);
        }
      });
  },

  onLoad: function (obj, request) {
    const callback = obj.callback,
      itemCallback = obj.itemCallback,
      errorCallback = obj.errorCallback;

    if (request.status >= 200 && request.status < 400 && callback) {
      if (typeof callback === "string") {
        bcl.u.executeFunctionByName(callback, window, request, itemCallback);
      } else if (typeof callback === "function") {
        callback(request, itemCallback);
      }
    }

    if (request.status >= 500) {
      if (typeof errorCallback === "function") {
        errorCallback(request, itemCallback);
      }
    }
  },

  putRequest: function (obj) {
    const data = obj.data,
      url = obj.url;

    if (!url) {
      return;
    }
    const isBearer = obj.contentType.includes("Bearer");
    const config = {};
    if (isBearer) {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: obj.contentType,
      };
    }

    axios
      .put(url, isBearer ? JSON.stringify(data) : data, config)
      .then(function (response) {
        bcl.ajax.onLoad(obj, response.request);
      })
      .catch(function (error) {
        console.error("Axios PUT error in url:", url);
        console.error("PUT error details:", error);
      });
  },
};
