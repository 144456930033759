/* Init commons */
window.bcl = typeof bcl !== "undefined" ? bcl : {};

bcl.c = typeof bcl.c !== "undefined" ? bcl.c : {};
bcl.u = typeof bcl.u !== "undefined" ? bcl.u : {};
bcl.s = typeof bcl.s !== "undefined" ? bcl.s : {};

bcl.load = typeof bcl.load !== "undefined" ? bcl.load : false;

if (typeof bcl.preInit === "undefined") {
  bcl.preInit = [];
}
