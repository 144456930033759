export function mutateBarceloComponents({ component, name, belongsTo }) {
  if (belongsTo !== "") {
    bcl.c = {
      ...bcl.c,
      [belongsTo]: {
        ...bcl.c[belongsTo],
        [name]: component,
      },
    };
  } else {
    bcl.c = {
      ...bcl.c,
      [name]: {
        ...bcl.c[name],
        ...component,
      },
    };
  }
}

export const isPresent = (selector, root = document) => (Array.isArray(selector) ? !!selector.some((item) => root.querySelector(item)) : !!root.querySelector(selector));

export const dynamicImport = ({ customName, name, belongsTo, component, initMethod, skipInit, root }) => {
  const componentName = customName !== "" ? customName : name;

  mutateBarceloComponents({ component: component.default, name: componentName, belongsTo });
  if (!skipInit) {
    if (root !== document && component.default[initMethod].length > 0) {
      component.default[initMethod](root);
    } else {
      component.default[initMethod]();
    }
  }

  return component.default;
};
